























































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useCategories } from '@/composition/schedule';
import { Category } from '@/admin/schedule';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcColor from '@/components/FcColor.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  reactionName: 'ScheduleCategories',
  components: {
    FcColor,
    FcRoleLoading,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('scheduleCategories'));

    const { error, notify } = useNotification();
    const { confirmDialog } = useConfirm();
    const categories = useCategories();
    const headers = [
      { text: '名前', value: 'name' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    // 追加
    const isSaving = ref(false);
    const isModalActive = ref(false);
    const openModal = (item?: Category) => {
      if (item) categories.item = item;
      isModalActive.value = true;
    };
    const closeModal = () => {
      categories.item = {
        scheduleCategoryId: '',
        name: '',
        color: '',
      };
      isModalActive.value = false;
    };

    // 保存
    const saveCategory = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        if (categories.item.scheduleCategoryId) {
          await categories.saveCategory();
        } else {
          await categories.createCategory();
        }
        closeModal();
        await categories.getCategories();
        notify('作成しました。');
      } catch (e) {
        error(e);
      } finally {
        isSaving.value = false;
      }
    };

    // 削除
    const deleteCategory = async (categoryId: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      isSaving.value = true;
      try {
        await categories.deleteCategory(categoryId);
        notify('削除しました。');
        await categories.getCategories();
      } catch (e) {
        error(e);
      } finally {
        isSaving.value = false;
      }
    };
    return {
      pageTitle: 'カテゴリー設定',
      myRoleSettings,
      categories,
      headers,
      tableHeight,
      deleteCategory,
      isSaving,
      isModalActive,
      saveCategory,
      openModal,
      closeModal,
    };
  },
});
