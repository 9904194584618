var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0"},[(!_vm.myRoleSettings)?_c('fc-role-loading',{attrs:{"my-role-settings":_vm.myRoleSettings,"page-title":_vm.pageTitle}}):[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),(_vm.myRoleSettings.createAndUpdate)?_c('v-btn',{staticClass:"mt-2 ml-3",attrs:{"elevation":"0","color":"primary","disabled":_vm.isSaving},on:{"click":function($event){return _vm.openModal()}}},[_vm._v("新規作成")]):_vm._e()],1),_c('v-sheet',{attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{staticClass:"fixed-header",attrs:{"headers":_vm.headers,"items":_vm.categories.items,"items-per-page":-1,"loader-height":"2","hide-default-footer":"","height":_vm.tableHeight,"loading":_vm.categories.isLoading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(_vm.myRoleSettings.createAndUpdate)?_c('a',{staticClass:"text-decoration-underline",style:(("color: " + (item.color) + ";")),on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',{style:(("color: " + (item.color) + ";"))},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.myRoleSettings.delete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","small":"","loading":_vm.isSaving},on:{"click":function($event){return _vm.deleteCategory(item.scheduleCategoryId)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("削除")])]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","no-click-animation":"","retain-focus":false,"max-width":"900"},model:{value:(_vm.isModalActive),callback:function ($$v) {_vm.isModalActive=$$v},expression:"isModalActive"}},[(_vm.isModalActive)?_c('v-card',[_c('v-card-text',{staticClass:"pt-5"},[_c('div',[_c('div',{staticClass:"body-2"},[_vm._v("カテゴリー名")]),_c('div',{staticClass:"body-1 fc-text pa-0"},[_c('v-text-field',{staticClass:"pa-0",model:{value:(_vm.categories.item.name),callback:function ($$v) {_vm.$set(_vm.categories.item, "name", $$v)},expression:"categories.item.name"}})],1)]),_c('div',{staticClass:"pt-5"},[_c('div',{staticClass:"body-2"},[_vm._v("色")]),_c('div',{staticClass:"body-1 fc-text pa-0"},[_c('fc-color',{staticClass:"pt-0",attrs:{"mode":"hexa"},model:{value:(_vm.categories.item.color),callback:function ($$v) {_vm.$set(_vm.categories.item, "color", $$v)},expression:"categories.item.color"}})],1)]),_c('div',{staticClass:"d-flex flex-wrap pt-10"},[_c('v-btn',{staticClass:"grey lighten-3 mr-2",attrs:{"text":"","elevation":"0"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){return _vm.saveCategory()}}},[_vm._v("保存")])],1)])],1):_vm._e()],1)],_c('v-btn',{staticClass:"mt-5",attrs:{"elevation":"0","color":"primary","to":"/schedules","disabled":_vm.isSaving}},[_vm._v("戻る")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }